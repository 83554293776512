import React from "react";
import type { VariantProps } from "class-variance-authority";
import { type clampVariants, Clamp } from "components/Clamp";
import { isPast } from "date-fns";
import cx from "classnames";
import { ReactComponent as TickIcon } from "components/assets/tick.svg";
import { Countdown } from "components/layout/Countdown";

import * as styles from "./EventStatus.module.scss";

export enum StatusEnum {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  LIVE = "LIVE",
  SETTLED = "SETTLED",
  CANCELLED = "CANCELLED",
  DEACTIVATED = "DEACTIVATED",
  WON = "WON",
  LOST = "LOST",
  VOID = "VOID",
  RESULTED = "RESULTED",
  CLOSED = "CLOSED",
  INTERIM = "INTERIM",
  PROTEST = "PROTEST",
  FINAL = "FINAL",
  DONE = "DONE",
  JUMPED = "JUMPED",
  ABANDONED = "ABANDONED",
}

type ClampVariant = VariantProps<typeof clampVariants>["variant"];
type ClampShape = VariantProps<typeof clampVariants>["shape"];

type EventStatusProps = {
  status: StatusEnum;
  scheduledStartTime: Date;
  shape?: ClampShape;
  clampClassName?: string;
  disabled?: boolean;
};

const statusToVariantMap: Record<StatusEnum, ClampVariant> = {
  [StatusEnum.SUSPENDED]: "neutral",
  [StatusEnum.LIVE]: "neutral",
  [StatusEnum.SETTLED]: "light",
  [StatusEnum.CANCELLED]: "neutral",
  [StatusEnum.DEACTIVATED]: "neutral",
  [StatusEnum.WON]: "success",
  [StatusEnum.LOST]: "danger",
  [StatusEnum.VOID]: "danger",
  [StatusEnum.RESULTED]: "light",
  [StatusEnum.CLOSED]: "dangerSoft",
  [StatusEnum.INTERIM]: "light",
  [StatusEnum.PROTEST]: "danger",
  [StatusEnum.FINAL]: "light",
  [StatusEnum.DONE]: "success",
  [StatusEnum.JUMPED]: "dangerSoft",
  [StatusEnum.ABANDONED]: "disabled",
  [StatusEnum.ACTIVE]: "grey",
};

const isValidStatus = (value: string): boolean =>
  Object.values(StatusEnum).includes(value as StatusEnum);

const getClampVariant = (status: StatusEnum): ClampVariant => {
  return statusToVariantMap[status] || "grey";
};

const EventStatus = ({
  status,
  shape,
  scheduledStartTime,
  clampClassName,
}: EventStatusProps) => {
  const hasStarted = isPast(scheduledStartTime);
  const variant = getClampVariant(status);
  const shouldShowStatus =
    status !== StatusEnum.ACTIVE && isValidStatus(status);

  const adjustedStatus =
    status === "SUSPENDED" && hasStarted ? StatusEnum.LIVE : status;

  if (shouldShowStatus) {
    return (
      <Clamp
        variant={variant}
        shape={shape}
        className={cx(clampClassName, {
          [styles.pulse]: [StatusEnum.CLOSED, StatusEnum.INTERIM].includes(
            adjustedStatus,
          ),
          [styles.live]: adjustedStatus === StatusEnum.LIVE,
        })}
      >
        {adjustedStatus === "FINAL" ? (
          <TickIcon className={styles.tick} />
        ) : null}
        {adjustedStatus}
      </Clamp>
    );
  }

  return (
    <Countdown
      scheduledStartTime={scheduledStartTime}
      shape={shape}
      clampClassName={clampClassName}
    />
  );
};

export { EventStatus, EventStatusProps };
