import { useState, useEffect, useMemo, useContext } from "react";
import { getFirestore } from "store/getFirebase";
import { useCollectionData, useDocumentData } from "hooks";
import {
  collection,
  type FirestoreDataConverter,
  doc,
  query,
  where,
} from "firebase/firestore";
import { usePermissions } from "hooks/useHasPermission";
import { FirebaseContext } from "context/Firebase";
import { mapCampaigns, marketFilter } from "utilities/sharedBettingUtilities";
import {
  createRaceMarketsConverter,
  createRacingEventsConverter,
  raceCompetitorsConverter,
} from "sections/Betting/Race/hooks/useRace";
import type {
  RaceEventType,
  RaceMarketsType,
} from "sections/Betting/Race/hooks/RacingTypes";
import {
  type Event,
  type EventMarket,
  type Outcomes,
  type Competitor,
  createEventsConverter,
  competitorsConverter,
  createMarketsConverter,
  campaignConverter,
} from "./betting/useBetting";
import type { Maybe } from "yup";

export enum CampaignPurposeEnum {
  /**
   * "Double you winnings" type promotion that is attached to a betting market and automatically claimed by the user when they bet on it.
   *
   */
  WINNINGS_BOOST,

  /**
   *  "Lose one leg of a 4+ leg multi and get cashback" type promotion
   *  that is automatically claimed by the user when qualifying bet is accepted.
   *
   */
  MULTI_CASHBACK,
  DEFAULT_REFERRAL,
  REFERRAL,
  PROMOTION,

  DEFAULT_DEPOSIT_BONUS,
  DEPOSIT_BONUS,
  PER_CLAIM_DEPOSIT_BONUS,

  BTAG_REFERRAL,
}

export type PromotionsType = {
  name: string;
  currency: string;
  description: string;
  code?: string;
  purpose?: CampaignPurposeEnum;
  termsOfUse?: string;
};

export enum BettingTabs {
  SPORTS_SINGLES = "SPORTS_SINGLES",
  SPORTS_SEM = "SPORTS_SEM",
  RACING_SINGLES = "RACING_SINGLES",
  RACING_SEM = "RACING_SEM",
  RACING_EXOTICS = "RACING_EXOTICS",
  RACING_TRIFECTA = "RACING_TRIFECTA",
  RACING_QUINELLA = "RACING_QUINELLA",
  RACING_FIRST_4 = "RACING_FIRST_4",
  RACING_EXACTA = "RACING_EXACTA",
}

export const ExoticTabs = [
  BettingTabs.RACING_TRIFECTA,
  BettingTabs.RACING_QUINELLA,
  BettingTabs.RACING_FIRST_4,
  BettingTabs.RACING_EXACTA,
];

export type CampaignsType = {
  id: string;
  name: string;
  description: string;
  openToAllEligibleUsers: boolean;
  promotions: Record<string, PromotionsType>;
  termsOfUse?: string;
  bettingTabs?: BettingTabs[];
};

export type PromotionsAvailableType =
  | "openToAllEligibleUsers"
  | "campaignIds"
  | null;

export type CombinedEvent = Event | RaceEventType;
type EventConverterType = FirestoreDataConverter<CombinedEvent>;

type CombinedMarket = EventMarket | RaceMarketsType;
type MarketConverterType = FirestoreDataConverter<CombinedMarket>;

type CampaignsConverterType = FirestoreDataConverter<CampaignsType>;

export type SourceType = "sport" | "race" | "srm";

export type marketFilterTypeProps = "all" | "sgm";

export enum AllowedSelectionTypesEnum {
  SameEventMulti = "SameEventMulti",
  SingleOutcome = "SingleOutcome",
}

const decorateOutcomes = (outcomes: Outcomes, event: Event): Outcomes =>
  Object.entries(outcomes).reduce((acc, [key, value]) => {
    if (value.type?.includes("CORRECT_SCORE")) {
      const competitorName = event?.competitors?.[value?.competitorId]?.name;
      if (competitorName) {
        acc[key] = {
          ...value,
          name: `${competitorName} ${value?.name}`,
        };
      } else {
        acc[key] = value;
      }
    } else if (value.type?.includes("PLAYER_HOME")) {
      acc[key] = {
        ...value,
        competitorId:
          Object.values(event?.mainMarket?.outcomes || {}).find(
            (x) => x.type === "FRANCHISE_HOME",
          )?.competitorId ?? value.competitorId,
      };
    } else if (value.type?.includes("PLAYER_AWAY")) {
      acc[key] = {
        ...value,
        competitorId:
          Object.values(event?.mainMarket?.outcomes || {}).find(
            (x) => x.type === "FRANCHISE_AWAY",
          )?.competitorId ?? value.competitorId,
      };
    } else {
      acc[key] = value;
    }

    return acc;
  }, {} as Outcomes);

// Helper function to process sports events
const processSportsEvent = ({
  event,
  markets,
  competitors,
  canViewLiveBets,
  userCampaigns,
  permissions,
}): Event => {
  const isEventLive =
    event.mainMarket?.bettingType === "Live" && event.status !== "SETTLED";

  return {
    ...event,
    eventName: event.eventName,
    eventType: event.eventType,
    competitors: competitors?.reduce(
      (acc, value) => {
        if (value) {
          acc[value.id] = {
            abbreviation: value.abbreviation,
            name: value.name,
            type: value.type,
            iconUri: value.iconUri,
            nationality: value.nationality,
            number: value.number,
            silksUrl: value.silksUrl,
            id: value.id,
          };
        }
        return acc as Competitor;
      },
      {} as Record<string, Competitor>,
    ),

    markets: (markets as EventMarket[])
      .filter((market) =>
        marketFilter({ event, market, userCampaigns, permissions }),
      )
      .map((market) => ({
        ...market,
        outcomes: decorateOutcomes(market.outcomes, event),
        status: !canViewLiveBets && isEventLive ? "SUSPENDED" : market.status,
      })),
  };
};

const processRacingEvent = ({
  event,
  markets,
  competitors,
  canViewLiveBets,
  userCampaigns,
  permissions,
}) => {
  const isEventLive =
    event.mainMarket?.bettingType === "Live" && event.status !== "SETTLED";

  return {
    ...event,
    eventName: `${event.venue} - R${event.number}`,
    eventType: "RACE",
    competitors: competitors?.reduce(
      (acc, value) => {
        if (value) {
          acc[value.id] = {
            abbreviation: value.number.toString(),
            name: value.name,
            type: "RACER",
            number: Number(value.number),
            silksUrl: event?.silksUrl,
          };
        }
        return acc;
      },
      {} as Record<string, Competitor>,
    ),
    markets: (markets as EventMarket[])
      .filter((market) =>
        marketFilter({ event, market, userCampaigns, permissions }),
      )
      .map((market) => ({
        ...market,
        name: market.name,
        outcomes: decorateOutcomes(market.outcomes, event),
        status: !canViewLiveBets && isEventLive ? "SUSPENDED" : market.status,
      })),
  };
};

const getCollectionProperties = (
  sourceType: SourceType,
  userCampaigns: string[],
  permissions: Record<string, string>,
) => {
  const racingEventsConverter = createRacingEventsConverter(
    userCampaigns,
    permissions,
  );
  const raceMarketsConverter = createRaceMarketsConverter(
    userCampaigns,
    permissions,
  );

  const sportsEventConverter = createEventsConverter(
    userCampaigns,
    permissions,
  );
  const sportsMarketsConverter = createMarketsConverter(
    userCampaigns,
    permissions,
  );

  return sourceType === "race"
    ? {
        collection: "racingEvents",
        converter: racingEventsConverter as unknown as EventConverterType,
        marketConverter: raceMarketsConverter,
        competitorsConverter: raceCompetitorsConverter,
        campaignConverter,
      }
    : {
        collection: "bettingEvents",
        converter: sportsEventConverter as unknown as EventConverterType,
        marketConverter: sportsMarketsConverter,
        competitorsConverter: competitorsConverter,
        campaignConverter,
      };
};

const processEventWithMarkets = ({
  event,
  markets,
  competitors,
  canViewLiveBets,
  userCampaigns,
  permissions,
  campaigns,
}) => {
  const isRaceEvent = ["HORSE_RACING", "GREYHOUNDS", "HARNESS_RACING"].includes(
    event?.sport,
  );

  const baseEvent = {
    ...event,
    hub: event?.hub || "racing",
    status:
      !canViewLiveBets && event.status === "LIVE" ? "SUSPENDED" : event.status,
    mainMarket: {
      ...event?.mainMarket,
      status:
        !canViewLiveBets &&
        isRaceEvent &&
        event.mainMarket?.bettingType === "Live"
          ? "SUSPENDED"
          : event?.mainMarket?.status,
    },
    attributes: { ...event.attributes, ...event, featured: true },
    campaigns: mapCampaigns(campaigns, userCampaigns, permissions),
  };

  return isRaceEvent
    ? {
        ...baseEvent,
        ...processRacingEvent({
          event,
          markets,
          competitors,
          canViewLiveBets,
          userCampaigns,
          permissions,
        }),
      }
    : {
        ...baseEvent,
        ...processSportsEvent({
          event,
          markets,
          competitors,
          canViewLiveBets,
          userCampaigns,
          permissions,
        }),
      };
};

const getMarketsQuery = (
  marketFilterType: marketFilterTypeProps,
  sourceType: SourceType,
) => {
  const query = [];

  if (sourceType === "race") {
    return query;
  }

  if (marketFilterType === "sgm") {
    query.push(
      where(
        "allowedSelectionTypes",
        "array-contains",
        AllowedSelectionTypesEnum.SameEventMulti,
      ),
    );
  } else {
    query.push(
      where(
        "allowedSelectionTypes",
        "array-contains",
        AllowedSelectionTypesEnum.SingleOutcome,
      ),
    );
  }

  return query;
};
/**
 * Should the market be displayed on the marketFilterType tab. Replicates the logic of the getMarketsQuery query above.
 *
 */
export const isVisibleOnTab = (
  marketFilterType: marketFilterTypeProps,
  market: EventMarket,
) => {
  if (!market) return false;
  if (marketFilterType === "sgm") {
    return market?.allowedSelectionTypes?.includes(
      AllowedSelectionTypesEnum.SameEventMulti,
    );
  }
  return true;
};

export type CombinedEventRace = Event | undefined;

export const useEvent = (
  eventId?: string,
  sourceTypeProp: SourceType = "sport",
  marketFilterType: marketFilterTypeProps = "all",
): [Maybe<CombinedEventRace>, boolean, boolean] => {
  const [sourceType, setSourceType] = useState<SourceType>(sourceTypeProp);
  const { campaigns: userCampaigns } = useContext(FirebaseContext);
  const permissions = usePermissions();
  const canViewLiveBets = permissions?.["viewLiveMarkets"] === "GRANTED";

  const collectionProperties = useMemo(
    () => getCollectionProperties(sourceType, userCampaigns, permissions),
    [sourceType, userCampaigns],
  );

  const eventsRef = eventId
    ? doc(
        getFirestore(),
        collectionProperties.collection,
        eventId,
      )?.withConverter(collectionProperties?.converter as EventConverterType)
    : null;

  const [eventWithMarkets, setEventWithMarkets] =
    useState<CombinedEventRace>(undefined);
  const [loading, setLoading] = useState(true);

  const marketsRef = eventsRef
    ? collection(eventsRef, "markets").withConverter(
        collectionProperties?.marketConverter as MarketConverterType,
      )
    : null;

  const campaignsRef = eventsRef
    ? collection(eventsRef, "campaigns").withConverter(
        collectionProperties?.campaignConverter as CampaignsConverterType,
      )
    : null;

  const competitorsRef = eventsRef
    ? collection(
        eventsRef,
        // competitors existed as a property on the racingEvents collection, but was moved to a subcollection on the bettingEvents collection
        sourceType === "race" ? "competitors" : "competitorsV2",
      ).withConverter(collectionProperties?.competitorsConverter as any)
    : null;

  const marketsQuery = getMarketsQuery(marketFilterType, sourceTypeProp);

  const [event, eventLoading] = useDocumentData(eventsRef);
  const [markets, marketsLoading] = useCollectionData(
    marketsRef ? query(marketsRef, ...marketsQuery) : null,
    marketsRef?.path,
  );

  const [competitors, competitorsLoading] = useCollectionData(
    competitorsRef,
    competitorsRef?.path,
  );

  const [campaigns, campaignsLoading] = useCollectionData(
    campaignsRef,
    campaignsRef?.path,
  );

  useEffect(() => {
    if (
      eventLoading ||
      marketsLoading ||
      competitorsLoading ||
      campaignsLoading
    ) {
      return;
    }

    if (!eventLoading && !marketsLoading && !event && sourceType === "sport") {
      setSourceType("race");
    }

    if (!event) {
      setLoading(false);
      return;
    }

    setEventWithMarkets(
      processEventWithMarkets({
        event,
        markets,
        competitors,
        canViewLiveBets,
        userCampaigns,
        permissions,
        campaigns,
      }),
    );

    setLoading(false);
  }, [
    event,
    markets,
    competitors,
    eventLoading,
    marketsLoading,
    userCampaigns,
    canViewLiveBets,
    sourceType,
    competitorsLoading,
    permissions,
  ]);

  return [eventWithMarkets, loading, marketsLoading];
};
