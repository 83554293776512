import { useMemo } from "react";
import { useCollectionData } from "hooks/firestore/useCollectionData";
import { collection, type QueryDocumentSnapshot } from "firebase/firestore";
import { getFirestore } from "store/getFirebase";
import { useSelector } from "hooks/useSelector";
import { isFuture } from "date-fns";

type Nullable<T> = T | null;

type ActionType = "STAKE_REFUND" | "WINNINGS_BOOST";

type Action = {
  info: string;
  status: string | null;
  type: ActionType;

  projectRewardInfo: {
    isPromo: boolean;
    max: Nullable<number>;
    percent: number;
  };

  userDrivenAttributes: null; // this will always be empty for tokens
};

type Requirement = {
  info: string;
  projectedRewardInfo: null;
  status: string | null;
  attributeInfos: string[];

  // tokens would ever only apply to stake
  type: "SEM_BET_STAKE" | "NON_SEM_BET_STAKE";

  userDrivenAttributes: {
    minimumStake?: number;
    maximumStake?: number;
    stakeSource?: "AVAILABLE" | "PROMO";
    marketIds?: string[];
    allMarketIdsMustMatch?: boolean;
    eventIds?: string[];
    allEventIdsMustMatch?: boolean;
    sports?: string[];
    allSportsMustMatch?: boolean;
    currencies?: string[];
    meetingIds?: string[];
    allMeetingIdsMustMatch?: boolean;
    seasonIds?: string[];
    allSeasonIdsMustMatch?: boolean;
    tournamentIds?: string[];
    allTournamentIdsMustMatch?: boolean;
    marketTypeNames?: string[];
    allMarketTypeNamesMustMatch?: boolean;

    // this is only for SEM
    minimumOdds?: number;
    minimumOutcomes?: number;
    maximumOutcomes?: number;

    // this is only for non-SEM
    minimumSelections?: number;
    maximumSelections?: number;
    minimumSelectionOdds?: number;
  };
};

type Automation = {
  automationId: string;
  name: string;
  actions: Action[];
  requirements: Requirement[];
  campaign: {
    bettingTabs: string[];
    name: string;
    openToAllEligibleUsers: boolean;
    provider: string;
    status: string;
  };
  requirementInfos: string[];
};

type Allocation = {
  id: string;
  automationId: string;
  expiresAt: Date;
  attributes: {
    vetoedEventIds: string[];
  };
  markedForDeletion: boolean;
};

type AutomationWithAllocations = Automation & {
  allocations: Allocation[];
};

type AutomationsGroup = {
  name: string;
  tokens: AutomationWithAllocations[];
};

type GroupedAllocations = Partial<Record<ActionType, AutomationsGroup>>;

const definitionsConverter = {
  toFirestore: (data: Automation) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) =>
    snapshot.data() as Automation,
};

const allocationsConverter = {
  toFirestore: (data: Allocation) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data();

    return {
      ...data,
      id: snapshot.id,
      expiresAt: data.expiresAt.toDate(),
    } as Allocation;
  },
};

const typeToNameMap: Record<ActionType, string> = {
  STAKE_REFUND: "Stake Refund",
  WINNINGS_BOOST: "Winnings Boost",
};

const useTokenDefinitions = () => {
  const ref = collection(getFirestore(), "tokenAutomations").withConverter(
    definitionsConverter,
  );

  return useCollectionData(ref, ref?.path);
};

const useTokens = (): [GroupedAllocations, boolean] => {
  const userId = useSelector((state) => state.auth.userId);
  const ref = userId
    ? collection(
        getFirestore(),
        "users",
        userId,
        "tokenAllocations",
      ).withConverter(allocationsConverter)
    : null;

  const [definitions, definitionsLoading] = useTokenDefinitions();
  const [allocations, allocationsLoading] = useCollectionData(ref, ref?.path);

  const groupedTokens = useMemo(() => {
    if (definitionsLoading || allocationsLoading) {
      return {};
    }

    return definitions.reduce(
      (tokensByType: GroupedAllocations, definition) => {
        // we can assume that a token will only have 1 action, so we can just grab the first one
        const action = definition.actions[0];

        if (!action) {
          return tokensByType;
        }

        // find all allocations that match this definition
        const allocationsForDefinition = (allocations || []).filter(
          (allocation) =>
            allocation.automationId === definition.automationId &&
            isFuture(allocation.expiresAt) &&
            !allocation.markedForDeletion,
        );

        // if group doesn't exist, create it
        if (!tokensByType[action.type]) {
          tokensByType[action.type] = {
            name: typeToNameMap[action.type],
            tokens: [],
          };
        }

        // add definition to group if there are active allocations
        if (allocationsForDefinition.length > 0) {
          tokensByType[action.type].tokens.push({
            ...definition,
            allocations: allocationsForDefinition,
          });
        }

        if (tokensByType[action.type].tokens.length === 0) {
          delete tokensByType[action.type];
        }

        return tokensByType;
      },
      {},
    );
  }, [definitions, definitionsLoading, allocations, allocationsLoading]);

  return [groupedTokens, definitionsLoading || allocationsLoading];
};

export { useTokens };
export type {
  Automation,
  AutomationWithAllocations,
  Allocation,
  ActionType,
  GroupedAllocations,
  AutomationsGroup,
};
