import React from "react";
import cx from "classnames";

import { Icon } from "library";
import * as support from "utilities/support";

import * as styles from "./BetId.module.scss";

export type BetIdType =
  | string
  | {
      contest?: string | number;
      ticket?: string;
      prefix?: string;
    };

type BetIdProps = {
  betId: BetIdType;
  className?: string;
};

export const BetId = ({ betId, className }: BetIdProps) => (
  <button
    className={cx({ [styles.betId]: true, [className]: !!className })}
    onClick={() => support.open({ search: "Ticket ID" })}
  >
    <div className={styles.icon}>
      <Icon type="entryId" />
    </div>
    <div className={styles.content}>
      <div className={styles.label}>Entry ID</div>
      <div className={styles.numbers}>
        {(betId &&
          (typeof betId === "string"
            ? betId
            : (betId.prefix ? betId.prefix + "-" : "") +
              (betId.contest || "X") +
              "-" +
              (betId.ticket || "X"))) ||
          "-"}
      </div>
    </div>
  </button>
);

export default BetId;
