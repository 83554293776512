import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { isAppleWebView } from "../display";
import {
  sendAppMessage,
  WEB_TO_APP_MESSAGE_TYPES,
} from "../AppWebMessagingBridge";

export type UIPanel = "betslip" | "pickslip" | "search" | "notifications";

export type UIMenu = "left" | "right" | "none";

export type Stream = {
  sport?: string;
  title: string;
  url: string;
  eventId?: string;
  muted?: boolean;
  playing?: boolean;
};

type Captcha = {
  required: boolean;
  visible: boolean;
};

type UIState = {
  panel: UIPanel;
  modal: string | null;
  menu: UIMenu;
  streams: Stream[];
  captcha: Captcha;
};

const initialState: UIState = {
  panel: "betslip",
  modal: null,
  menu: "none",
  streams: [],
  captcha: {
    required: false,
    visible: false,
  },
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setPanel(state: UIState, action: PayloadAction<UIPanel>) {
      state.panel = action.payload;
    },
    setModal(state: UIState, action: PayloadAction<string | null>) {
      state.modal = action.payload;
    },
    setMenu(state: UIState, action: PayloadAction<UIMenu>) {
      state.menu = action.payload;
    },
    setCaptchaPuzzle(state: UIState, action: PayloadAction<Captcha>) {
      state.captcha = {
        required: action.payload.required,
        visible: action.payload.visible,
      };
    },
    closeCaptchaPuzzle(state: UIState) {
      state.captcha = {
        required: false,
        visible: false,
      };
    },
    toggleMenu(state: UIState) {
      if (state.menu === "left") {
        state.menu = "none";
      } else {
        state.menu = "left";
      }
    },
    togglePanel(state: UIState, action: PayloadAction<UIPanel>) {
      if (state.menu === "right" && state.panel === action.payload) {
        state.menu = "none";
      } else {
        state.menu = "right";
        state.panel = action.payload;
      }
    },
    toggleBetslip(state: UIState) {
      if (state.menu === "right" && state.panel === "betslip") {
        state.menu = "none";
      } else {
        state.menu = "right";
        state.panel = "betslip";
      }
    },
    toggleStream(state: UIState, action: PayloadAction<Stream>) {
      const index = state.streams.findIndex(
        (stream) => stream.eventId === action.payload.eventId,
      );

      if (index !== -1) {
        state.streams.splice(index, 1);
      } else {
        state.streams.unshift(action.payload);
      }
    },
    closeStream(state: UIState, action: PayloadAction<Stream>) {
      const index = state.streams.findIndex(
        (stream) => stream.eventId === action.payload.eventId,
      );
      if (index !== -1) state.streams.splice(index, 1);
    },
    closeAllStreams(state: UIState) {
      state.streams = [];
    },
    toggleMuteStream(state: UIState, action: PayloadAction<Stream>) {
      const index = state.streams.findIndex(
        (stream) => stream.eventId === action.payload.eventId,
      );
      state.streams[index].muted = !state.streams[index].muted;
    },
    requestStream() {
      // no state update
    },
    streamHadError(_state: UIState, _action: PayloadAction<unknown>) {
      // no state update
    },
    messageTimedOut() {
      // no state update
    },
    duplicateBetAttempted() {
      // no state update
    },
    appCrashed() {
      // no state update
    },
  },
});

export const openStream = (stream: Stream) => (dispatch) => {
  dispatch(requestStream());
  if (isAppleWebView()) {
    sendAppMessage(WEB_TO_APP_MESSAGE_TYPES.APP_MESSAGE_PLAY_STREAM, stream);
  } else {
    dispatch(toggleStream(stream));
  }
};

export const closeAllModals = () => (dispatch) => {
  dispatch(setModal(null));
  dispatch(setMenu("none"));
  dispatch(closeAllStreams());
};

export const {
  setPanel,
  setModal,
  setMenu,
  requestStream,
  streamHadError,
  messageTimedOut,
  duplicateBetAttempted,
  appCrashed,
  closeStream,
  toggleMuteStream,
  toggleStream,
  closeAllStreams,
  toggleMenu,
  toggleBetslip,
  togglePanel,
  setCaptchaPuzzle,
  closeCaptchaPuzzle,
} = uiSlice.actions;

export default uiSlice.reducer;
