export function getTimeToRead(post: Record<string, any>): number {
  let result = 0;
  if (post !== null && typeof post === "object") {
    for (const key in post) {
      if (post[key]) {
        const value = post[key];
        if (key === "timeToRead") {
          return Number(value);
        }
        if (typeof value === "object") {
          result += getTimeToRead(value);
        }
      }
    }
  }
  return result;
}

export const optimizeBackgroundImageUrl = (
  url: string,
  width?: number,
  height?: number,
) => {
  if (!width && !height) return `url('${url}?fm=webp')`;
  if (width && !height) return `url('${url}?w=${width}&fm=webp')`;
  if (!width && height) return `url('${url}?h=${height}&fm=webp')`;
  return `url('${url}?w=${width}&h=${height}&fm=webp')`;
};
