import React, { type ReactNode, useState } from "react";
import cx from "classnames";

import { InputError } from "components/InputError";
import { Typography } from "components/Typography";
import { Skeleton } from "components/Skeleton";

import * as styles from "./Input.module.scss";

type InputAttribute = React.DOMAttributes<HTMLInputElement>;

export type InputProps = {
  label?: ReactNode;
  containerClassName?: string;
  inputClassName?: string;
  wrapperClassName?: string;
  errorMessage?: string;
  hasError?: boolean;
  icon?: React.ReactNode;
  loading?: boolean;
  iconContainerClassName?: string;
  /** Remove the margin from around the element */
  noSpacing?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      errorMessage,
      label,
      containerClassName,
      inputClassName,
      hasError,
      icon,
      onFocus,
      onBlur,
      disabled,
      wrapperClassName,
      loading = false,
      iconContainerClassName,
      noSpacing,
      ...props
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus: InputAttribute["onFocus"] = (event) => {
      setIsFocused(true);
      if (onFocus) onFocus(event);
    };

    const handleBlur: InputAttribute["onBlur"] = (event) => {
      setIsFocused(false);
      if (onBlur) onBlur(event);
    };

    if (props.type === "hidden") {
      return <input type={`hidden`} {...props} />;
    }

    return (
      <div
        className={cx(styles.container, containerClassName, {
          [styles.noSpacing]: noSpacing,
        })}
        data-testid={`input-container`}
      >
        {label && (
          <Typography
            component="label"
            className={styles.label}
            skeleton={{ loading }}
            htmlFor={id}
            noSpacing
          >
            {label}
          </Typography>
        )}
        {loading ? (
          <Skeleton />
        ) : (
          <>
            <div
              data-testid={`input-wrapper`}
              className={cx(
                styles.inputWrapper,
                {
                  [styles.focus]: isFocused,
                  [styles.hasError]: !!errorMessage || hasError,
                  [styles.disabled]: !!disabled,
                  [styles.hasIcon]: !!icon,
                },
                wrapperClassName,
              )}
            >
              <input
                id={id}
                className={cx(styles.input, inputClassName)}
                ref={ref}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={disabled}
                {...props}
              />
              {icon && (
                <div className={cx(styles.icon, iconContainerClassName)}>
                  {icon}
                </div>
              )}
            </div>
            {errorMessage && <InputError message={errorMessage} />}
          </>
        )}
      </div>
    );
  },
);

Input.displayName = "Input";

export { Input };
