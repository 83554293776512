import cx from "classnames";
import { constructCurrency } from "common";
import { ReactComponent as ShareIcon } from "components/assets/share.svg";
import { FirebaseContext } from "context/Firebase";
import { useFiatCurrency } from "hooks";
import { BetOdds } from "library";
import has from "lodash/has";
import React, { useContext, type PropsWithChildren } from "react";
import type { BetEntry } from "sections/Entries/types";
import type { OddsFormat } from "types/BetTypes";
import { BetSummaryContext, useBetSummaryContext } from "./BetSummaryContext";
import { ShareEntryAction } from "./share/ShareEntryAction";
import { useShareEntryModal } from "./share/useShareEntryModal";
import { Payout } from "sections/Betting/Betslip/components/Payout";

import * as styles from "./Summary.module.scss";

type BetSummaryProps = PropsWithChildren<{
  entry: BetEntry;
  isCompact: boolean;
  isWon: boolean;
}>;

type BetStakeProps = {
  separator?: React.ReactNode;
  omitStakeValue?: boolean;
  label?: string;
};

type BetOddsSummaryProps = {
  separator?: React.ReactNode;
};

type BetStakePerComboProps = {
  separator?: React.ReactNode;
  omitStakeValue?: boolean;
};

type BetStakeHiddenProps = {
  label?: string;
};

export const BetSummary = ({
  entry,
  isCompact,
  isWon,
  children,
}: BetSummaryProps) => {
  const currency = useFiatCurrency();
  const oddsFormat = useContext(FirebaseContext)?.userPreferences?.oddsFormat;

  return (
    <BetSummaryContext.Provider
      value={{ entry, currency, oddsFormat: oddsFormat ?? "" }}
    >
      <div
        className={cx(styles.summary, {
          [styles.isWon]: isWon,
          [styles.isCompact]: isCompact,
        })}
      >
        {children}
      </div>
    </BetSummaryContext.Provider>
  );
};

export const BetStake = ({
  separator,
  omitStakeValue,
  label,
}: BetStakeProps) => {
  const {
    entry: { isPromo, stake },
    currency,
  } = useBetSummaryContext();

  if (omitStakeValue) return <BetStakeHidden label={label ?? "Stake"} />;

  return (
    <div className={cx({ [styles.isPromo]: isPromo })}>
      <span className={styles.label}>{label ?? "Stake"}</span>
      <span className={styles.value}>
        <div>{constructCurrency(stake, { currency })}</div>

        {isPromo && <div className={styles.bonus}>bonus</div>}
      </span>
      <span className={cx({ [styles.circle]: !!separator })}>{separator}</span>
    </div>
  );
};

export const BetStakeHidden = ({ label }: BetStakeHiddenProps) => {
  return (
    <div>
      <div className={styles.stakeHidden}>
        <div>
          <div className={styles.label}>{label ?? "Stake"}</div>
          <div className={styles.value}>HIDDEN</div>
        </div>
        <div className={styles.emoji}>🤫</div>
      </div>
    </div>
  );
};

export const BetOddsSummary = ({ separator }: BetOddsSummaryProps) => {
  const {
    entry: { status, odds, isSP, picks },
    oddsFormat,
  } = useBetSummaryContext();

  const isMulti = picks.length > 1;
  const spOdds = isMulti ? "-" : "SP";

  return (
    <div className={cx(styles.odds, { [styles.oddsOnly]: !separator })}>
      <span className={styles.label}>Odds</span>
      <span className={styles.value}>
        {isSP ? (
          spOdds
        ) : (
          <BetOdds
            base={odds}
            format={oddsFormat as OddsFormat}
            zero={`1.00`}
            isVoid={status === "VOID"}
          />
        )}
      </span>
      <span className={cx({ [styles.circle]: !!separator })}>{separator}</span>
    </div>
  );
};

export const BetStakePerCombo = ({
  separator,
  omitStakeValue,
}: BetStakePerComboProps) => {
  const {
    entry: { stake, combinationsCount, isExotic, picks },
  } = useBetSummaryContext();
  const currency = useFiatCurrency();

  // stakePerCombo refers to combinations multis, different to exotics
  const stakePerCombo = stake / combinationsCount;

  const numberOfExoticCombinations =
    picks.find((pick) => has(pick, "exoticCombinations"))?.exoticCombinations ??
    0;

  const exoticStakePerCombo = stake / numberOfExoticCombinations;

  const label = "Stake/Combo";

  if (omitStakeValue) return <BetStakeHidden label={label} />;

  return (
    <div className={cx(styles.odds, { [styles.oddsOnly]: !separator })}>
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>
        <div>
          {constructCurrency(isExotic ? exoticStakePerCombo : stakePerCombo, {
            currency,
          })}
        </div>
      </span>
      <span className={cx({ [styles.circle]: !!separator })}>{separator}</span>
    </div>
  );
};

export const BetPayout = () => {
  const {
    entry: {
      isSP,
      payOut,
      isCombo,
      isExotic,
      decided,
      combinationsCount,
      picks,
      selectionsRequiredToWin,
      stake,
    },
  } = useBetSummaryContext();

  let payoutAmount: number | string = payOut;

  if (isExotic) {
    payoutAmount = decided ? payOut : "TBD";
  } else if (isCombo) {
    if (decided) {
      payoutAmount = payOut;
    } else {
      const highestComboOdds = picks
        .map(({ odds }) => odds)
        .sort((a, b) => b - a)
        .slice(0, selectionsRequiredToWin)
        .reduce((a, b) => a * b, 1);

      payoutAmount = highestComboOdds
        ? (stake / combinationsCount) * highestComboOdds
        : "TBD";
    }
  }

  // Payout Amount
  if (payoutAmount !== "TBD" && !decided && isSP) {
    payoutAmount = "TBD";
  }

  return (
    <div>
      <span className={styles.label}>Payout</span>
      <span className={styles.value}>
        <Payout amount={payoutAmount} />
      </span>
    </div>
  );
};

export const BetSummaryAction = () => {
  const { entry } = useBetSummaryContext();
  const [_, getModalProps] = useShareEntryModal();

  return (
    <ShareEntryAction
      {...getModalProps({
        referenceId: entry.referenceId,
        className: styles.action,
        icon: <ShareIcon />,
        entry: [entry, false],
      })}
    />
  );
};
