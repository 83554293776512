// Firebase Config
// Generates and returns the raw Firebase config object used to init the firebase
// instance.

const firebaseRawConfig = {
  apiKey: process.env.GATSBY_FIREBASE_KEY,
  authDomain:
    process.env.GATSBY_FIREBASE_AUTH_DOMAIN ||
    `${process.env.GATSBY_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL:
    process.env.GATSBY_FIREBASE_DATABASE_URI ||
    `https://${process.env.GATSBY_FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  appCheckSiteKey: process.env.GATSBY_RECAPTCHA_V3_SITE_KEY,
  appCheckEnterpriseSiteKey: process.env.GATSBY_RECAPTCHA_ENTERPRISE_SITE_KEY,
  appCheckDevToken: process.env.GATSBY_APPCHECK_TOKEN,
  databaseId: process.env.GATSBY_FIREBASE_DATABASE_ID,
};

export { firebaseRawConfig };
