// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "Filters-module--emphasis--fcdf1";
export var filters = "Filters-module--filters--c5917";
export var green = "#57d33b";
export var greenLink = "Filters-module--green-link--9039e";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var selected = "Filters-module--selected--c9151";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";