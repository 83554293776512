import React from "react";
import cx from "classnames";
import { constructCurrency, IF_REQUIRED } from "common";

import Button from "library/components/Button";
import Icon from "library/components/Icon";

import ContestMetric from "./ContestMetric";
import ContestMedia from "./ContestMedia";
import BetId from "library/components/BetId";

import { formatTimeRemaining } from "utilities/datetime";

import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import type { BetIdType } from "library/components/BetId/BetId";
import { FirebaseContext } from "context/Firebase";
import { PageContext } from "context/Page";
import { getCurrencyForRegion } from "common/regionCurrencies";

import * as styles from "./ContestHeader.module.scss";

export interface IContestHeaderProps {
  title: string;
  category: string;
  logo?: string | string[];
  loaded?: boolean;
  aux?: JSX.Element | string;
  contest: {
    startTime: Date;
    description: string;
    prizePool: number;
    entryFee: number;
    submittedEntryCount: number;
    maximumEntries?: number;
    number: number;
    currency: string;
    state: string;
  };
  streams?: any[];
  entryDOM?: JSX.Element;
  betId: BetIdType;
  onInfoClick?: () => void;
}

export class ContestHeader extends React.Component<IContestHeaderProps> {
  timer: any = null;

  componentDidMount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(this.timerTick, 1000);
    this.timerTick();
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  timerTick = () => {
    const { contest } = this.props;
    if (contest && contest.startTime) {
      this.setState({ ...this.state });
    }
  };

  render() {
    const {
      title = "",
      category = "",
      loaded = true,
      aux = null,
      contest = null,
      betId = null,
      onInfoClick = null,
      streams = [],
      logo,
    } = this.props;

    const currency = contest?.currency || "USD";
    const prizePool = contest?.prizePool || 0;
    const entryCount = contest?.submittedEntryCount || 0;
    const entryLimit = contest?.maximumEntries;
    const status = contest?.state || contest?.status;

    const timeLeftString = formatTimeRemaining({
      startTime: contest?.startTime,
      state: status,
    });

    let entryCountString = "";
    if (loaded) {
      entryCountString = "0";
      if (entryCount) {
        entryCountString = entryCount + "";
      }
      if (entryLimit) {
        entryCountString += ` / ${entryLimit}`;
      }
    }

    const iconDollar = <Icon type="ticket" width={16} />;
    const iconPlayers = <Icon type="players" width={16} />;
    const iconMoneyBag = <Icon type="bagOfCash" width={16} />;
    const iconTimer = <Icon type="stopwatch" width={16} />;

    const mediaDom = <ContestMedia overlay={logo} streams={streams} />;

    let url = "https://picklebet.com";
    if (typeof window !== "undefined") {
      url = window.location.href;
    }

    return (
      <div
        className={cx({
          [styles.header]: true,
          [styles.loading]: !loaded,
        })}
      >
        <div className={styles.mediaArea}>{mediaDom}</div>

        <PageContext.Consumer>
          {(pageContext) => (
            <FirebaseContext.Consumer>
              {(context) => (
                <div className={styles.content}>
                  <div className={styles.identity}>
                    <div className={styles.titles}>
                      <div className={styles.category}>{category}</div>
                      <div className={styles.title}>{title}</div>
                    </div>
                  </div>
                  <div className={styles.bottom}>
                    <div className={styles.metrics}>
                      <ContestMetric
                        loaded={loaded}
                        title="Entry Fee"
                        icon={iconDollar}
                      >
                        {loaded
                          ? contest.entryFee === 0
                            ? "FREE"
                            : constructCurrency(contest.entryFee, {
                                currencyType: currency,
                                showDecimals: IF_REQUIRED,
                                userCurrency:
                                  context.profile?.fiatCurrency ||
                                  getCurrencyForRegion(pageContext?.region),
                              })
                          : ""}
                      </ContestMetric>

                      <ContestMetric
                        loaded={loaded}
                        title="Entries"
                        icon={iconPlayers}
                      >
                        {entryCountString}
                      </ContestMetric>

                      <ContestMetric
                        loaded={loaded}
                        title="Prize Pool"
                        icon={iconMoneyBag}
                      >
                        {loaded
                          ? constructCurrency(prizePool, {
                              currencyType: currency,
                              showDecimals: false,
                              userCurrency:
                                context.profile?.fiatCurrency ||
                                getCurrencyForRegion(pageContext?.region),
                            })
                          : ""}
                      </ContestMetric>

                      <ContestMetric
                        className={styles.metricCounter}
                        loaded={loaded}
                        title="Time Till Start"
                        icon={iconTimer}
                      >
                        {timeLeftString}
                      </ContestMetric>
                    </div>

                    <div className={styles.buttons}>
                      {aux}

                      {typeof onInfoClick === "function" && (
                        <Button onClick={onInfoClick} type="primary">
                          Contest Info
                        </Button>
                      )}
                    </div>
                  </div>

                  <div className={styles.betId}>
                    <BetId betId={betId} />
                  </div>

                  <div className={styles.share}>
                    <FacebookShareButton url={url} quote={title}>
                      <FacebookIcon size={24} round />
                    </FacebookShareButton>

                    <TwitterShareButton url={url} title={title}>
                      <TwitterIcon size={24} round />
                    </TwitterShareButton>

                    <WhatsappShareButton url={url} title={title}>
                      <WhatsappIcon size={24} round />
                    </WhatsappShareButton>

                    <TelegramShareButton url={url} title={title}>
                      <TelegramIcon size={24} round />
                    </TelegramShareButton>

                    <EmailShareButton url={url} subject={title}>
                      <EmailIcon size={24} round />
                    </EmailShareButton>
                  </div>
                </div>
              )}
            </FirebaseContext.Consumer>
          )}
        </PageContext.Consumer>
      </div>
    );
  }
}

export default ContestHeader;
