import React from "react";
import cx from "classnames";

import ContestImage from "./ContestImage";

import * as styles from "./ContestHeader.module.scss";

export const ContestMedia: React.SFC<{
  className?: string;
  overlay?: string | string[];
  streams?: string[];
}> = ({ className, overlay, children }) => (
  <div
    className={cx({
      [`${styles.media}`]: true,
      [`${className}`]: !!className,
      media: true,
    })}
  >
    {children}
    <ContestImage image={overlay} />
  </div>
);

export default ContestMedia;
