import React from "react";

import * as styles from "./Filters.module.scss";

export const Filters: React.SFC<{
  values: Array<string | [string, string]>;
  onSelect: (option: string[]) => void;
  selected?: string[];
  className?: string;
  mustHaveOne?: boolean;
}> = ({
  values,
  onSelect,
  selected = [],
  className = "",
  mustHaveOne = false,
}) =>
  values && (
    <ul className={[styles.filters, className].join(" ")}>
      {values.map((x: string | [string, string]) => {
        const value = x instanceof Array ? x[0] : (x as string);
        const title = x instanceof Array ? x[1] : (x as string);
        const checked = selected.includes(value);

        const handleChange = () => {
          const newSelected = selected.slice();
          if (!checked) {
            newSelected.push(value);
          } else if (!mustHaveOne || newSelected.length > 1) {
            const index = newSelected.indexOf(value);
            newSelected.splice(index, 1);
          }
          onSelect(newSelected);
        };

        return (
          <li key={value}>
            <input
              type="checkbox"
              id={value}
              name={value}
              checked={checked}
              onChange={handleChange}
            />
            <label htmlFor={value}>{title}</label>
          </li>
        );
      })}
    </ul>
  );
