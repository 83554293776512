import * as React from "react";
import cx from "classnames";
import { type VariantProps, cva } from "class-variance-authority";
import { Typography } from "components/Typography";
import { Separator } from "components/Separator";
import { RequiresAuth } from "components/layout/RequiresAuth";
import SEO from "library/components/Section/SEO";

import * as styles from "./Page.module.scss";

const pageVariants = cva(null, {
  variants: {
    variant: {
      // using CVA because there will be more variants later
      centered: styles.centered,
      full: styles.full,
    },
  },
  defaultVariants: {
    variant: "centered",
  },
});

type PageProps = VariantProps<typeof pageVariants> & {
  className?: string;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  showSeparator?: boolean;
  children?: React.ReactNode;
  requiresAuth?: boolean;
  noSpacing?: boolean;
  banner?: React.ReactNode;
  hideTitleOnMobile?: boolean;
};

const Page = ({
  showSeparator = true,
  title,
  subTitle,
  children,
  className,
  variant,
  requiresAuth = false,
  noSpacing = false,
  banner,
  hideTitleOnMobile = false,
}: PageProps) => {
  const Wrapper = requiresAuth ? RequiresAuth : React.Fragment;
  const missingTitleAndSeparator = !showSeparator && !subTitle;
  const shouldAddSpacing = noSpacing ? false : missingTitleAndSeparator;
  const titleIsAString = typeof title === "string";

  return (
    <>
      <SEO />
      <Wrapper>
        <div className={cx(styles.page, pageVariants({ variant }), className)}>
          {titleIsAString ? (
            <Typography
              variant={`h1`}
              noSpacing
              className={cx({
                [styles.withSpacing]: shouldAddSpacing,
                [styles.hideOnMobile]: hideTitleOnMobile,
              })}
            >
              {title}
            </Typography>
          ) : (
            title
          )}
          {subTitle && (
            <Typography variant={`body`} className={styles.subTitle} noSpacing>
              {subTitle}
            </Typography>
          )}
          {banner}
          {showSeparator && <Separator />}
          {children}
        </div>
      </Wrapper>
    </>
  );
};

const PageContent = ({
  children,
  noSpacing,
  className,
}: {
  children: React.ReactNode;
  noSpacing?: boolean;
  className?: string;
}) => {
  return (
    <div
      className={cx(
        styles.pageContent,
        {
          [styles.noSpacing]: noSpacing,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

export { Page, PageContent };
