import BigNumber from "bignumber.js";
import type { PromotionsAvailableType } from "hooks";
import type {
  Event as EventType,
  EventMarket,
  Outcome as OutcomeType,
  Outcomes as OutcomesType,
  OutcomeWithId,
} from "hooks/firestore/betting/useBetting";
import type {
  RaceEventType,
  RaceMarketsType,
} from "sections/Betting/Race/hooks/RacingTypes";
import type { CampaignsType } from "hooks/firestore/useEvent";
import cloneDeep from "lodash/cloneDeep";
import {
  type OutcomeTypeWithId,
  OUTCOME_NAMES,
} from "sections/Betting/Event/Outcomes";
import type {
  BetSelectionType,
  CompetitorType,
  FranchiseType,
} from "types/BetTypes";
import { identity } from "lodash";

const BASE_ICON_URL = "https://res.cloudinary.com/skrilla/image/upload";

export const isOutright = (eventType: EventType["eventType"]): boolean => {
  return ["TOURNAMENT", "SEASON", "OUTRIGHT"].includes(eventType);
};

/** Takes in a betting event and returns the home, away & draw outcomes which
 * are extracted from the main market object
 */
export const getHeadToHeadOutcomes = (
  event: EventType,
): {
  homeOutcome?: OutcomeWithId;
  awayOutcome?: OutcomeWithId;
  drawOutcome?: OutcomeWithId;
} => {
  if (!event.mainMarket) return {};

  const outcomeEntries = Object.entries(event.mainMarket.outcomes || {});

  const [homeOutcomeId, homeOutcome] =
    outcomeEntries.find(
      ([_, outcome]) =>
        typeof outcome.type === "string" && outcome.type.endsWith("HOME"),
    ) ?? [];

  const [awayOutcomeId, awayOutcome] =
    outcomeEntries.find(
      ([_, outcome]) =>
        typeof outcome.type === "string" && outcome.type.endsWith("AWAY"),
    ) ?? [];

  const [drawOutcomeId, drawOutcome] =
    outcomeEntries.find(
      ([_, outcome]) =>
        typeof outcome.type === "string" && outcome.type === "DRAW",
    ) ?? [];

  return {
    homeOutcome: homeOutcome && { ...homeOutcome, id: homeOutcomeId },
    awayOutcome: awayOutcome && { ...awayOutcome, id: awayOutcomeId },
    drawOutcome: drawOutcome && { ...drawOutcome, id: drawOutcomeId },
  };
};

export const getDefaultBettingPath = (defaultHub: string) =>
  defaultHub === "racing"
    ? "/racing/betting/"
    : defaultHub === "sports"
      ? "/sports/betting/"
      : "/betting/";

export const mapCampaigns = (
  campaigns: any,
  userCampaigns: string[],
  permissions: Record<string, string>,
) =>
  permissions["viewPromotion"] === "GRANTED" &&
  (campaigns as CampaignsType[])?.reduce((acc, value) => {
    if (value?.openToAllEligibleUsers || userCampaigns.includes(value.id)) {
      acc.push(value);
    }

    return acc;
  }, [] as CampaignsType[]);

export const getIsSGMReady = (sgmAvailableAtDate: Date | undefined) => {
  if (!sgmAvailableAtDate) return;

  return new Date() > sgmAvailableAtDate;
};

export const getIsPromotionAvailable = (
  promotionVisibility: PromotionsAvailableType,
  eventCampaignIds: string[],
  userCampaigns: string[],
  permissions: Record<string, string>,
): boolean => {
  if (permissions["viewPromotion"] !== "GRANTED" || !promotionVisibility) {
    return false;
  }

  if (promotionVisibility === "openToAllEligibleUsers") {
    return true;
  }

  if (promotionVisibility === "campaignIds") {
    if (!userCampaigns || !eventCampaignIds) return false;

    return userCampaigns.some((campaign) =>
      eventCampaignIds.includes(campaign),
    );
  }

  return false;
};

export const marketFilter = ({
  market,
  userCampaigns,
  event,
  permissions,
}: {
  market: EventMarket | RaceMarketsType;
  userCampaigns: string[];
  event?: EventType | RaceEventType; // Betting only
  permissions: Record<string, string>;
}): boolean => {
  // exclude promo markets if there is no permission
  if (market.promotional && permissions["viewPromotion"] !== "GRANTED") {
    return false;
  }

  if (
    ((event as EventType)?.hub === "sports" &&
      permissions?.["viewSportsMarkets"] !== "GRANTED") ||
    ((event as EventType)?.hub === "esports" &&
      permissions?.["viewEsportsMarkets"] !== "GRANTED") ||
    (event &&
      event.sport &&
      (event.sport === "HORSE_RACING" ||
        event.sport === "GREYHOUNDS" ||
        event.sport === "HARNESS_RACING") &&
      permissions?.["viewRacingMarkets"] !== "GRANTED") ||
    permissions["viewBettingMarkets"] !== "GRANTED"
  ) {
    return false;
  }

  // exclude markets that are set as campaign only and not matching campaign id
  if (
    market.campaignOnly &&
    !(userCampaigns || []).some((campaignId) =>
      (market.campaignIds || []).includes(campaignId),
    )
  ) {
    return false;
  }

  if (
    Object.values(market.outcomes ?? {}).every(
      (outcome) => outcome.result === "VOID",
    )
  ) {
    return false;
  }

  if (
    ["sports", "esports"].includes((event as EventType)?.hub) &&
    Object.values(market.outcomes ?? {}).some(
      (outcome: OutcomeType) =>
        outcome.active && outcome.odds === 0 && outcome.openingOdds === 0,
    )
  ) {
    return false;
  }

  if ((event as EventType)?.mainMarket?.status === "ACTIVE") {
    return (market as EventMarket)?.status !== "DEACTIVATED";
  }

  return true;
};

export const marketSearch = ({
  markets,
  searchFilter,
  mainMarketStatus,
}: {
  markets: EventMarket[];
  searchFilter: string;
  mainMarketStatus?: string;
}): EventMarket[] => {
  // we only show markets that have at least one outcome that is active or if the main market is active
  const validMarkets = cloneDeep(markets)?.map((market) => {
    if (market?.marketType?.includes("HANDICAP")) {
      return market;
    }

    // Filter out any outcomes based on mainMarketStatus and outcome.active
    const validOutcomes = Object.fromEntries(
      Object.entries(market?.outcomes || {}).filter(
        ([, outcome]) =>
          (mainMarketStatus === "ACTIVE" && outcome.active) ||
          mainMarketStatus !== "ACTIVE",
      ),
    );

    return { ...market, outcomes: validOutcomes };
  });

  if (searchFilter === "") {
    return validMarkets;
  }

  const searchFilterLower = searchFilter.toLowerCase();

  return validMarkets.reduce((_markets, _market) => {
    const filteredOutcomes = Object.entries(_market.outcomes || {}).reduce(
      (acc, [outcomeId, outcome]) => {
        if (outcome?.name?.toLowerCase().includes(searchFilterLower)) {
          acc[outcomeId] = outcome;
        }
        return acc;
      },
      {},
    );

    if (
      Object.keys(_market.outcomes || {}).length > 0 &&
      _market.name.toLowerCase().includes(searchFilterLower)
    ) {
      _markets.push(_market);
    } else if (
      Object.keys(filteredOutcomes || {}).length > 0 &&
      _market?.marketType?.includes("HANDICAP")
    ) {
      _markets.push(_market);
    } else if (Object.keys(filteredOutcomes || {}).length > 0) {
      _markets.push({ ..._market, outcomes: filteredOutcomes });
    }

    return _markets;
  }, []);
};

export const buildCloudinaryTransformation = (
  entity: CompetitorType | FranchiseType | any,
  size: number,
) => {
  const transformation = [];

  if (!entity?.iconUri) return null;

  if (size) {
    const baseSized = parseInt(size as any, 10);
    transformation.push(`w_${baseSized}`);
    transformation.push(`h_${baseSized}`);
  }

  if (entity?.type?.toLowerCase() === "PLAYER") {
    transformation.push("c_thumb");
    transformation.push("g_face");
    transformation.push("e_sharpen");
  } else if (entity?.type?.toLowerCase() === "FRANCHISE") {
    transformation.push("f_auto");
    transformation.push("q_auto");
  } else {
    transformation.push("f_auto");
    transformation.push("q_auto");
  }

  const finalUrl = entity.iconUri.replace(
    BASE_ICON_URL,
    `${BASE_ICON_URL}/${transformation.join(",")}`,
  );

  return finalUrl;
};

const findNum = /[-+]?[0-9]+\.?[0-9]*/;

const createDummyOutcome = (
  outcome: OutcomeTypeWithId,
  handicap: string,
  pairs: string[] = ["OVER", "UNDER"],
): OutcomeTypeWithId => {
  let abbreviation = outcome?.abbreviation;

  // Check if handicap contains one of the pairs (case-insensitive)
  if (
    pairs.some((pair) => handicap.toLowerCase().includes(pair.toLowerCase()))
  ) {
    abbreviation = handicap;
  } else {
    abbreviation = abbreviation?.replace(
      outcome.abbreviation.match(findNum)?.[0] ?? "",
      handicap,
    );
  }

  const name = outcome?.name?.replace(
    outcome.name.match(findNum)?.[0] ?? "",
    handicap,
  );

  return {
    ...outcome,
    id: `${handicap}-${name}`,
    name: name,
    abbreviation: abbreviation,
    odds: 1,
    active: false,
    result: "UNDECIDED",
  };
};

const oppositeValue = (value: string, pairs: string[]) => {
  const [pair1, pair2] = pairs.map((pair) => pair.toLowerCase());

  const matchPair1 = value.match(new RegExp(pair1, "i"));
  const matchPair2 = value.match(new RegExp(pair2, "i"));

  if (matchPair1) {
    const firstCharCase =
      matchPair1[0].charAt(0) === matchPair1[0].charAt(0).toUpperCase()
        ? "uppercase"
        : "lowercase";
    const replacement =
      firstCharCase === "uppercase"
        ? pair2.charAt(0).toUpperCase() + pair2.slice(1).toLowerCase()
        : pair2.toLowerCase();
    return value.replace(new RegExp(pair1, "i"), replacement);
  } else if (matchPair2) {
    const firstCharCase =
      matchPair2[0].charAt(0) === matchPair2[0].charAt(0).toUpperCase()
        ? "uppercase"
        : "lowercase";
    const replacement =
      firstCharCase === "uppercase"
        ? pair1.charAt(0).toUpperCase() + pair1.slice(1).toLowerCase()
        : pair1.toLowerCase();
    return value.replace(new RegExp(pair2, "i"), replacement);
  }

  const numMatch = value.match(/[-+]?\d+(\.\d+)?/);
  if (numMatch) {
    const bNum = new BigNumber(numMatch[0]);
    if (numMatch[0].startsWith("+") || !numMatch[0].startsWith("-")) {
      return value.replace(numMatch[0], "-" + bNum.abs().toString());
    } else {
      return value.replace(numMatch[0], "+" + bNum.abs().toString());
    }
  }

  return value;
};

export const generateOppositeOutcomes = (
  firstOutcomes: any[],
  secondOutcomes: any[],
  pairs: string[],
): { first: string; second: string }[] => {
  const result = [];

  for (const first of firstOutcomes) {
    const opposite = secondOutcomes.find(
      (value) => value === oppositeValue(first, pairs),
    );

    if (opposite !== undefined) {
      result.push({ first: first.toString(), second: opposite.toString() });
      secondOutcomes = secondOutcomes.filter((value) => value !== opposite);
    } else {
      result.push({
        first: first.toString(),
        second: oppositeValue(first, pairs),
      });
    }
  }

  for (const second of secondOutcomes) {
    result.push({ first: oppositeValue(second, pairs), second: second });
  }

  return result.sort((a, b) => {
    // sort by first number
    const aFirstNumber = parseFloat(a.first.match(findNum)?.[0] ?? 0);
    const bFirstNumber = parseFloat(b.first.match(findNum)?.[0] ?? 0);

    return aFirstNumber - bFirstNumber;
  });
};

const TEAM_NAMES_WITH_NUMS = {
  "49ers": "Forty Niners",
  "76ers": "Seventy Sixers",
  "36ers": "Thirty Sixers",
};

export const sortPairedOutcomes = (
  outcomes: OutcomesType,
  pairs: string[] = ["UNDER", "OVER"],
  stringOpposites = false,
): OutcomeTypeWithId[] => {
  if (pairs.length !== 2) {
    console.error("The pairs array must have exactly two elements.");
    return [];
  }

  if (Object.entries(outcomes ?? {}).length === 1) {
    return Object.entries(outcomes).map(([id, outcome]) => ({
      id,
      ...outcome,
    }));
  }

  const makeOutcomesNamesNumberSafe = (
    outcomes: OutcomesType,
  ): OutcomeTypeWithId[] => {
    return Object.entries(outcomes).map(([id, outcome]) => {
      return {
        ...outcome,
        id,
        name: Object.keys(TEAM_NAMES_WITH_NUMS).reduce(
          (acc, teamName) =>
            acc.replace(teamName, TEAM_NAMES_WITH_NUMS[teamName]),
          outcome.name,
        ),
        abbreviation: outcome.abbreviation
          ? Object.keys(TEAM_NAMES_WITH_NUMS).reduce(
              (acc, teamName) =>
                acc.replace(teamName, TEAM_NAMES_WITH_NUMS[teamName]),
              outcome.abbreviation,
            )
          : undefined,
      };
    });
  };

  const makeOutcomesNamesNumberSafeReverse = (
    outcomes: OutcomeTypeWithId[],
  ): OutcomeTypeWithId[] => {
    return outcomes.filter(identity).map((outcome) => {
      let name = outcome.name;
      Object.keys(TEAM_NAMES_WITH_NUMS).forEach((key) => {
        name = name.replace(TEAM_NAMES_WITH_NUMS[key], key);
      });

      let abbreviation = outcome.abbreviation;

      if (outcome.abbreviation) {
        Object.keys(TEAM_NAMES_WITH_NUMS).forEach((key) => {
          abbreviation = abbreviation?.replace(TEAM_NAMES_WITH_NUMS[key], key);
        });
      }

      return { ...outcome, name, abbreviation };
    });
  };

  const firstPairOutcomes: OutcomeTypeWithId[] = [];
  const secondPairOutcomes: OutcomeTypeWithId[] = [];

  const safeOutcomes = makeOutcomesNamesNumberSafe(outcomes);

  for (const outcome of safeOutcomes) {
    if (outcome.type.includes(pairs[0])) {
      firstPairOutcomes.push({
        ...outcome,
        value: stringOpposites
          ? outcome.abbreviation ?? outcome.name
          : outcome.abbreviation?.match(findNum)?.[0] ??
            outcome.name?.match(findNum)?.[0],
      });
    } else if (outcome.type.includes(pairs[1])) {
      secondPairOutcomes.push({
        ...outcome,
        value: stringOpposites
          ? outcome.abbreviation ?? outcome.name
          : outcome.abbreviation?.match(findNum)?.[0] ??
            outcome.name?.match(findNum)?.[0],
      });
    }
  }

  firstPairOutcomes.sort(
    (a, b) => parseFloat(a.abbreviation) - parseFloat(b.abbreviation),
  );
  secondPairOutcomes.sort(
    (a, b) => parseFloat(b.abbreviation) - parseFloat(a.abbreviation),
  );

  const oppositeOutcomesMatrix = generateOppositeOutcomes(
    firstPairOutcomes.map((outcome) => outcome.value),
    secondPairOutcomes.map((outcome) => outcome.value),
    pairs,
  );

  const sortedOutcomes = [] as OutcomeTypeWithId[];
  for (const num of oppositeOutcomesMatrix) {
    let firstOutcome: OutcomeTypeWithId, secondOutcome: OutcomeTypeWithId;

    if (firstPairOutcomes?.[0]) {
      firstOutcome =
        firstPairOutcomes.find((outcome) => outcome.value === num.first) ??
        createDummyOutcome(firstPairOutcomes?.[0], num.first, pairs);
    }

    if (secondPairOutcomes?.[0]) {
      secondOutcome =
        secondPairOutcomes.find((outcome) => outcome.value === num.second) ??
        createDummyOutcome(secondPairOutcomes?.[0], num.second, pairs);
    }

    if (firstOutcome.active || secondOutcome.active) {
      /**
       * only one active outcome is required to render both, this keeps the
       * outcome groups even on the match page and the dummy one is always
       * disabled, without odds. It is presentation only.
       * */
      sortedOutcomes.push(firstOutcome);
      sortedOutcomes.push(secondOutcome);
    }
  }

  return makeOutcomesNamesNumberSafeReverse(sortedOutcomes);
};

export const sortOutcomes = (
  outcomes: OutcomesType,
  isOutright: boolean,
  marketType: string,
): OutcomeTypeWithId[] => {
  // Map to store preprocessed outcomes.
  const outcomesWithInfo = Object.entries(outcomes).map(([id, outcome]) => {
    const name =
      outcome?.abbreviation?.toLowerCase() ?? outcome?.name?.toLowerCase();
    const numberMatch = name?.match(/[-+]?\d*\.?\d+/g);
    const numbersWithDashMatch = name?.match(/[-+]?\d*\.?\d+-[-+]?\d*\.?\d+/g);
    return {
      id,
      outcome,
      name,
      hasNumber: !!numberMatch,
      number: numberMatch ? parseFloat(numberMatch[0]) : 0,
      numbersWithDash: numbersWithDashMatch
        ? parseFloat(numbersWithDashMatch[0].replace("-", ""))
        : 0,
      isOverUnder: name?.includes("over") || name?.includes("under"),
      nameWithoutNumber: name?.replace(numberMatch?.[0] ?? "", ""),
      nameWithoutNumbersWithDash: name?.replace(
        numbersWithDashMatch?.[0] ?? "",
        "",
      ),
    };
  });

  const someOutcomeNamesHaveNumbers = outcomesWithInfo.some(
    (info) => info.hasNumber,
  );

  return outcomesWithInfo
    .sort((infoA, infoB) => {
      const { outcome: outcomeA, name: outcomeAName, number: numAInt } = infoA;
      const { outcome: outcomeB, name: outcomeBName, number: numBInt } = infoB;

      if (!outcomeAName || !outcomeBName) {
        return 0;
      }

      if (!outcomeAName || !outcomeB.name) {
        return 0;
      }

      if (isOutright || marketType === "CUSTOM") {
        return outcomeA.odds - outcomeB.odds;
      }

      if (
        marketType === "CORRECT_MAP_SCORE" &&
        outcomeA.abbreviation &&
        outcomeB.abbreviation
      ) {
        return (
          OUTCOME_NAMES.indexOf(outcomeA.abbreviation) -
          OUTCOME_NAMES.indexOf(outcomeB.abbreviation)
        );
      }

      if (
        marketType.includes("CORRECTSCORE") ||
        marketType.includes("CORRECT_SCORE")
      ) {
        if (
          infoA.nameWithoutNumbersWithDash === infoB.nameWithoutNumbersWithDash
        ) {
          return infoA.numbersWithDash - infoB.numbersWithDash;
        }

        return infoA.nameWithoutNumber.localeCompare(infoB.nameWithoutNumber);
      }

      if (outcomeAName.includes("player tries")) {
        return outcomeA.odds - outcomeB.odds;
      }

      if (outcomeAName.includes("team event")) {
        return outcomeA.name.localeCompare(outcomeB.name);
      }

      if (someOutcomeNamesHaveNumbers) {
        if (infoA.isOverUnder && numAInt === numBInt) {
          return outcomeB.name.localeCompare(outcomeA.name);
        }

        if (numAInt !== numBInt) {
          return numAInt - numBInt;
        }

        // If the number is the same, then compare by the odds
        return outcomeA.odds - outcomeB.odds;
      }

      // Default to sorting by odds
      if (outcomeA.odds && outcomeB.odds) {
        return outcomeA.odds - outcomeB.odds;
      }

      return outcomeA.name.localeCompare(outcomeB.name);
    })
    .map(({ id }) => ({ ...outcomes[id], id }));
};

export type Direction = "up" | "down";
export const getOddsChangeDirection = (
  prevOdds: number | undefined,
  odds: number,
): Direction | undefined => {
  // if we don't have previous odds then we can't have a direction
  if (!prevOdds || prevOdds === odds) return;

  return prevOdds > odds ? "down" : "up";
};

export const rounded = (amount: number, decimalPlaces = 2) =>
  BigNumber(amount).dp(decimalPlaces, BigNumber.ROUND_FLOOR).toNumber();

const ONE_CENT = 0.01;

/** ensures the stake is divisible by number of combinations and meets the 1c
 * per combo requirement */
export const safeExoticStake = (stake: number, combinations: number) => {
  if (stake === 0) return 0;
  const stakeInCents = rounded(stake * 100);

  if (stake / combinations < ONE_CENT) {
    // enforce 1c bet per combo as a minimum
    return ONE_CENT * combinations;
  }

  if (stakeInCents % combinations > 0) {
    const newStake = stakeInCents - (stakeInCents % combinations);
    return newStake / 100;
  } else {
    return stakeInCents / 100;
  }
};

export const getCurrentOdds = (selection: BetSelectionType) =>
  selection?.changes?.newOdds || selection.odds;
