import React from "react";
import cx from "classnames";

import * as styles from "./ContestHeader.module.scss";

export const ContestMetric: React.SFC<{
  className?: string;
  loaded: boolean;
  icon: JSX.Element;
  title: string;
}> = ({ className, loaded, icon, title, children }) => (
  <div
    className={cx(className, {
      [styles.metric]: true,
      [styles.loading]: !loaded,
    })}
  >
    <div className={styles.metricTop + "  top"}>
      {icon}
      <div>{children}</div>
    </div>
    {title && <div className={styles.metricLabel + " label"}>{title}</div>}
  </div>
);

export default ContestMetric;
