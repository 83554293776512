import { LOCALES } from "appConfig";
import type { IContentItem } from "types/ContentTypes";
import { getState } from "store";

export type QueryMap = {
  any?: string[];
  [key: string]: string[] | string | number;
};

export function routeStrip(path: string): string {
  let route = (path || "").replace(/\\/g, "/").toLowerCase();
  if (route.charAt(0) === "/") {
    route = route.substr(1);
  }
  for (const locale of LOCALES) {
    if (route.startsWith(locale)) {
      route = route.replace(locale, "");
    }
    if (route.charAt(0) === "/") {
      route = route.substr(1);
    }
  }
  if (route.length > 1 && route.charAt(route.length - 1) === "/") {
    route = route.substr(0, route.length - 1);
  }
  const queryI = path.indexOf("?");
  if (queryI > 0) {
    route = route.substr(0, queryI);
  }
  return route;
}

export function getPath() {
  return typeof location !== "undefined"
    ? location.pathname
    : getState()?.utilities?.ui?.route;
}

export function getHash() {
  let hash = typeof location !== "undefined" ? location.hash : "";
  if (hash.charAt(0) === "#") {
    hash = hash.substr(1);
  }
  return hash;
}

export function getRoute() {
  if (typeof location !== "undefined") {
    return routeStrip(getPath());
  }
  return "";
}

export interface IRouteInfo {
  path: string;
  route: string;
  parts: string[];
  query: Record<string, string>;
  hash?: string;
}

function cleanPath(path: string) {
  path = (path || "").trim();
  if (path.endsWith("/")) {
    path = path.substr(0, path.length - 1);
  }
  return path;
}

export function getRouteInfo(path?: string): IRouteInfo {
  path = cleanPath(path || getPath());
  const route = routeStrip(path);
  const parts = routeSplit(path);
  const query = getQuery(path);
  const hash = getHash();
  return { path, route, parts, query, hash };
}

export function getQuery(path: string) {
  const queryI = path?.indexOf("?") || -1;
  const params: Record<string, string> = {};
  if (queryI > 0) {
    const query = path.substr(queryI + 1);
    const queryPairs = query.split("&");
    for (const pairStr of queryPairs) {
      const eqI = pairStr.indexOf("=");
      const key = eqI >= 0 ? pairStr.substr(0, eqI) : pairStr;
      const value = eqI >= 0 ? pairStr.substr(eqI + 1) : "";
      params[key] = value;
    }
  }
  return params;
}

export function routeSub(route: string, params: Record<string, any>) {
  for (const key in params) {
    if (params[key]) {
      route = route.replace("{" + key + "}", params[key]);
    }
  }
  return route;
}

export function routeSplit(path: string): string[] {
  return routeStrip(path).split("/");
}

export function routeParam(
  path: string,
  param: string,
  defaultValue: string = null,
): string {
  const parts = routeSplit(path);
  const partI = parts?.indexOf(param) ?? -1;
  if (partI < 0) {
    return "";
  }
  const result = parts[partI + 1];
  return result || defaultValue;
}

export function selectRouteContent(items: IContentItem[]) {
  const route = getRoute();
  let result = items.find((x) => x.isActive);
  if (!result) {
    const activeIndices = items.map((x, i) => [
      i,
      ("/" + route).indexOf(x.link),
    ]);
    activeIndices.sort((a, b) => (a[1] > b[1] ? -1 : 1));
    const active = items[activeIndices?.[0]?.[0] || 0];
    result = active;
  }
  return result;
}
