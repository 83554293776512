// extracted by mini-css-extract-plugin
export var betId = "BetId-module--betId--7bd74";
export var blue = "#0eb3f2";
export var content = "BetId-module--content--75772";
export var emphasis = "BetId-module--emphasis--a5db8";
export var green = "#57d33b";
export var greenLink = "BetId-module--green-link--a0209";
export var grey = "var(--tone-mid)";
export var icon = "BetId-module--icon--37177";
export var label = "BetId-module--label--20efd";
export var notchGap = "32px";
export var numbers = "BetId-module--numbers--c6d7d";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";