import type {
  IBetPickPickems,
  IPickemsContestStats,
  PickemsStatus,
} from "types/PickemsTypes";

import type { IBetPick, IMarket, IMatchEvent } from "types/BetTypes";
import { FireTransforms } from "utilities/FireTransforms";
import { BettingTransforms } from "./BettingTransforms";
import { titleCase } from "title-case";
import type { ButtonType } from "library/components/ButtonWithState/Button";
import type { PickemsContest } from "hooks/firestore/pickems/types";
import type { PickType } from "sections/Entries/types";
import type { Titles } from "hooks/graphql/useTitles";

export namespace PickemsTransforms {
  export const getPlayAction = (
    contest: PickemsContest,
    entries?: any[],
  ): string => {
    if (contest.marketCounts.active < 1) {
      return "View Contest";
    }
    const contestEntries =
      entries?.filter((x) => x.contestId === contest.id) ?? [];
    if (
      contestEntries.length > 0 &&
      contestEntries.length < contest.marketCounts.total
    ) {
      return "Revise Picks";
    }
    return "Select Picks";
  };

  export const getButtonType = (contest: PickemsContest): ButtonType => {
    switch (contest?.status) {
      case "PENDING":
        return "success";
      default:
        return "primary";
    }
  };

  export const isContestDisabled = (contest: PickemsContest): boolean => {
    switch (contest?.status) {
      case "PENDING":
      case "LIVE":
        return false;
      default:
        return true;
    }
  };

  export const getContestLink = (contestId: string, tab = "pick") => {
    return `/pickems/contest/${contestId}/${tab}/`;
  };

  export const getPlayLink = (contestId: string) => {
    return getContestLink(contestId, "pick");
  };

  export const getLadderLink = (contestId: string) => {
    return getContestLink(contestId, "results");
  };

  export const getPlayerCount = (contest: PickemsContest) => {
    return contest?.entries?.total || 0;
  };

  export const getEntryFee = () => {
    return "FREE";
  };

  export const getWinnings = (contest: PickemsContest) => {
    return "US$" + Math.round(contest.prizePool[0] / 100);
  };
  export const parseContestStats = (
    contest: PickemsContest,
    selections: PickType[] = [],
    picks: IBetPickPickems[] = [],
  ): IPickemsContestStats => {
    const pendingPicks = picks.filter(
      (pick) =>
        !selections.find((selection) => pick.marketId === selection.eventId),
    );
    const marketCount = contest?.marketCounts?.total || 0;
    const pendingPickCount = pendingPicks.length;
    const remainingPickCount = pendingPicks.length + selections.length;
    return {
      contest,
      picks,
      pendingPicks,
      selections,
      marketCount,
      remainingPickCount,
      pendingPickCount,
      pointsEntered: parsePointsEntered(contest, selections, picks),
    };
  };

  export const parseContestStatus = (status: PickemsStatus) => {
    switch (status) {
      case "PENDING":
        return "UPCOMING";
    }
    return status as string;
  };

  export const parsePointsEntered = (
    contest: PickemsContest,
    selections: PickType[] = [],
    picks: IBetPickPickems[] = [],
  ) => {
    let result = 0;
    for (const selection of selections) {
      const newPick = picks.find(
        (x) => x.marketId === selection.marketId && x.sourceId === contest?.id,
      );
      if (!newPick?.odds) {
        result += Number(selection.odds) || 0;
      }
    }
    picks = picks.filter((x) => x.sourceId === contest?.id);
    for (const pick of picks) {
      result += Number(pick.odds) || 0;
    }
    return result;
  };

  export const getPickDetails = (pick: IBetPick, contest: PickemsContest) => {
    const market = contest?.markets.find(
      (market) => market.id === pick.marketId,
    );
    const outcome = market?.outcomes[pick.outcomeId as any];
    const marketName =
      (market?.event?.sport || "") + (market?.event?.eventName || "");
    const selectionName =
      outcome?.competitor?.name || titleCase(outcome?.type ?? "");
    const details: IBetPickPickems = {
      ...pick,
      contest,
      competitor: outcome?.competitor,
      eventName: market?.event?.eventName,
      freshOdds: outcome?.odds,
      lockedOdds: pick?.odds,
      selectionName,
      marketName,
      accepted: false,
      scheduledStartTime: market?.event?.scheduledStartTime,
      outcome,
    };
    return details;
  };

  export const getContestFrequencyDescription = () => {
    return "throughout the week";
  };

  export const getLogos = (sports: string[], titles: Titles) => {
    return (
      sports
        ?.map((x) => titles?.[x.toLowerCase()]?.logo?.file?.url)
        .filter(Boolean) || []
    );
  };

  export const getHeroes = (sports: string[], titles: Titles) => {
    return (
      sports
        ?.map((x) => titles?.[x.toLowerCase()]?.hero?.gatsbyImageData)
        .filter(Boolean) || []
    );
  };

  export const totalPrizePoolAsDouble = (values: number[]) => {
    let result = 0;
    if (values?.length) {
      for (const p of values) {
        result += p;
      }
      result /= 100;
    }
    return result;
  };

  export const totalPrizePool = (values: number[]) => {
    const double = totalPrizePoolAsDouble(values);
    return double ? "US$" + double : "-";
  };

  export const isContestActive = (status: PickemsStatus) => {
    switch (status) {
      case "PENDING":
      case "LIVE":
        return true;
    }
    return false;
  };

  export const isPickAvailable = (market: IMarket, event: IMatchEvent) => {
    if (BettingTransforms.marketIsActive(market, "PICKEMS") === false) {
      return false;
    }
    const start = FireTransforms.parseDate(
      market?.nextBetStop || event?.scheduledStartTime,
    );
    return start > new Date();
  };
}
