// extracted by mini-css-extract-plugin
export var betId = "ContestHeader-module--betId--feac9";
export var blue = "#0eb3f2";
export var bottom = "ContestHeader-module--bottom--3a5e6";
export var buttons = "ContestHeader-module--buttons--06588";
export var category = "ContestHeader-module--category--84851";
export var content = "ContestHeader-module--content--0dee7";
export var emphasis = "ContestHeader-module--emphasis--33b2f";
export var green = "#57d33b";
export var greenLink = "ContestHeader-module--green-link--6ae63";
export var grey = "var(--tone-mid)";
export var header = "ContestHeader-module--header--5d13f";
export var identity = "ContestHeader-module--identity--2838c";
export var loading = "ContestHeader-module--loading--4d81e";
export var media = "ContestHeader-module--media--e309f";
export var mediaArea = "ContestHeader-module--mediaArea--0967f";
export var metric = "ContestHeader-module--metric--c7f06";
export var metricCounter = "ContestHeader-module--metricCounter--4afed";
export var metricLabel = "ContestHeader-module--metricLabel--e68ab";
export var metricTop = "ContestHeader-module--metricTop--af3e0";
export var metrics = "ContestHeader-module--metrics--d5e85";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var share = "ContestHeader-module--share--0d15d";
export var title = "ContestHeader-module--title--7a2dd";
export var titles = "ContestHeader-module--titles--ff424";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";